import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, AlertTitle, Box, Button, Divider, Grid, Link, Stack, Typography, alpha } from '@mui/material';
import { useTheme } from '@mui/styles';
import { NewReleases } from '@mui/icons-material';
import { DOCS } from '../../utils/helper/link-config';

const ShortLink = (props) => {
    const { link, title } = props;
    return (
        <Link color="inherit" target="_blank" href={link}>
            {title}
        </Link>
    )
}

export default function UserRoleIntroBanner(props) {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Alert severity="info" icon={<NewReleases />}>
            <AlertTitle><b>nanoStream User Roles are here!</b></AlertTitle>
            Learn more about the feature. <ShortLink link="https://www.nanocosmos.de/blog/nanostream-user-roles-are-here/" title="Let's go →" /> <br />
            <Divider sx={{ my: 1 }} />
            <b>Useful Resources</b>: <ShortLink link={`${DOCS}/docs/user_roles#getting-started`} title="Getting Started" /> | <ShortLink link={`${DOCS}/docs/user_roles#user-roles-in-nanostream-cloud`} title="User Roles in nanoStream Cloud" /> | <ShortLink link={`${DOCS}/docs/user_roles#high-level-permission-overview`} title="High-level Permission Overview" /> | <ShortLink link={`${DOCS}/docs/cloud-frontend-v3/Dashboard_User_Roles`} title="User Management in nanoStream Cloud Dashboard" />
        </Alert>
    )
}
